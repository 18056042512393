import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Option = ({ title, description, path }) => {
  const navigate = useNavigate();

  return (
    <Col className='mb-3' xs='12' md='6'>
      <Card className='h-100 clickable' onClick={() => navigate(path)}>
        <Card.Header>
          <Card.Title>
            <h3>{title}</h3>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <h5>{description}</h5>
        </Card.Body>
      </Card>
    </Col>
  );
};

export const MainView = () => {
  return (
    <div className='w-100 d-flex flex-column align-items-center'>
      <Row className='w-100'>
        <Option
          path='/api-config'
          title='Configuration'
          description='Configure the connection between the aggregator and your FHIR API'
        />
        <Option
          path='/eva'
          title='Expected Vs Actual'
          description='Test your FHIR API by comparing response data with the expected outcome.'
        />
        {/* <Option
          path='/bolt'
          title='Performance Testing'
          description='Run performance tests to ensure your API meets all performance requirements.'
        /> */}
      </Row>
    </div>
  );
};
