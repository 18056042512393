import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useConfigApiClient } from '../../../../api';
import { useActiveResourceType } from '../../../../context';
import { ConfirmationModal, Input, Loading, Title } from '../../../component';
import { getErrorMessage, useFormikRef, useModalControl, useUser } from '../../../util';
import { ApiConfigCard } from './ApiConfigCard';
import { ClientDetailsCard } from './ClientDetailsCard';
import { RuntimeTokensHelpModal } from './RuntimeTokensHelpModal';

// ------------------------------------------------------------------------------------------

const initialClientDetailsValues = {
  name: '',
  clientId: '',
  mappingKey: '',
};

const internalEnvs = [
  {
    name: 'dev',
    label: 'Dev',
    downloadCsr: false,
    uploadCert: true,
  },
  {
    name: 'nft',
    label: 'NFT',
    downloadCsr: false,
    uploadCert: true,
  },
];

const publicEnvs = [
  {
    name: 'int',
    label: 'Sandbox',
    downloadCsr: true,
    uploadCert: true,
  },
  {
    name: 'aos',
    label: 'AOS (End-To-End)',
    downloadCsr: true,
    uploadCert: true,
  },
];

// ------------------------------------------------------------------------------------------

const getUniqueClientIds = (configs) => {
  return [...new Set(configs?.map((conf) => conf.clientId) || [])].sort();
};

// ------------------------------------------------------------------------------------------

export const HomeView = () => {
  const [clientId, setClientId] = useState();
  const [focusedEnv, setFocusedEnv] = useState(null);

  const destroyModal = useModalControl();
  const runtimeTokensHelpModal = useModalControl();
  const clientDetailsFormikRef = useFormikRef();

  const { clientDetails, meta } = useUser();
  const client = useConfigApiClient();
  const configs = client.useAll();
  const destroy = client.useDestroy();
  const resourceType = useActiveResourceType();

  const envs = meta.isAdmin || meta.isInternalUser ? [...internalEnvs, ...publicEnvs] : publicEnvs;

  const filteredConfigs = meta.isAdmin
    ? configs.data?.filter((c) => c.clientId?.startsWith(clientId)) || []
    : (!configs.loading && configs.data) || [];

  useEffect(() => {
    configs.execute();
  }, [resourceType]);

  const onClientIdChange = (e) => {
    if (meta.isAdmin) {
      const id = e.target.value;
      const conf = configs.data?.find((c) => c.clientId === id);

      setClientId(id);
      clientDetailsFormikRef.current.setValues(
        conf ? { name: conf.name, clientId: conf.clientId, mappingKey: conf.mappingKey } : initialClientDetailsValues
      );
    }
  };

  const onSaveComplete = () => {
    configs.execute();

    if (meta.isAdmin && clientId == null) {
      setClientId(clientDetailsFormikRef.current?.values?.clientId);
    }
  };

  const onDeleteClick = (i) => {
    setFocusedEnv(i);
    destroyModal.open();
  };

  const destroyConfig = () => {
    if (clientId != null && focusedEnv != null) {
      const env = envs[focusedEnv];
      const config = filteredConfigs.find((c) => c.env === env.name);

      destroy
        .execute({ env: config.env, clientId: config.clientId })
        .then(() => {
          toast(`Config was successfully deleted`, { type: 'success' });
          setFocusedEnv(null);
          configs.execute();
          destroyModal.close();
        })
        .catch((e) => {
          toast(`Error deleting ${envs.find((e) => e.name === config.env).label} config: ${getErrorMessage(e)}`, {
            type: 'warning',
          });
        });
    }
  };

  const renderConfigCard = (env, i) => {
    const values = filteredConfigs.find((c) => c.env === env.name);
    const deletable = clientId && meta.isAdmin && values != null;

    return (
      <ApiConfigCard
        env={env}
        key={`${env.name}.${clientId || ''}.${resourceType}`}
        values={values}
        clientId={clientId || clientDetails?.id}
        onSaveComplete={onSaveComplete}
        clientDetailsFormikRef={clientDetailsFormikRef}
        onDeleteClick={deletable && (() => onDeleteClick(i))}
        onHelpClick={runtimeTokensHelpModal.open}
      />
    );
  };

  if (filteredConfigs.length === 0 && configs.loading) {
    return (
      <div className='w-100'>
        <Title text='API Configuration' />
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className='w-100'>
        <Title text='API Configuration'>
          {meta.isAdmin && (
            <>
              <Input
                type='select'
                label='Client ID'
                value={clientId}
                defaultValue='default'
                onChange={onClientIdChange}
              >
                <option value='default'>Create new config</option>
                {getUniqueClientIds(configs.data).map((cid, i) => (
                  <option key={i} value={cid} label={cid} />
                ))}
              </Input>
            </>
          )}
        </Title>
        {meta.isAdmin && <ClientDetailsCard innerRef={clientDetailsFormikRef} />}
        {envs.map(renderConfigCard)}
      </div>
      <RuntimeTokensHelpModal show={runtimeTokensHelpModal.isOpen} onHide={runtimeTokensHelpModal.close} />
      {clientId && (
        <ConfirmationModal
          show={destroyModal.isOpen}
          onHide={destroyModal.close}
          loading={destroy.loading}
          onConfirm={destroyConfig}
          title='Delete Configuration'
          message={
            <>
              Are you sure you want to <b className='text-danger'>delete</b> this <b>{envs[focusedEnv]?.label}</b>{' '}
              configuration?
            </>
          }
        />
      )}
    </>
  );
};
