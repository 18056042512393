import { Spinner } from 'react-bootstrap';

export const InlineIconButton = ({ className, onClick, src, alt, loading, disabled, active = true, ...rest }) => {
  disabled = disabled || loading;

  return (
    <span
      className={`${className || ''} ${active ? 'active' : ''} ${disabled ? 'disabled' : ''} icon-button`}
      onClick={!disabled ? onClick : undefined}
      {...rest}
    >
      {loading ? <Spinner className='icon' animation='border' /> : <img className='icon' src={src} alt={alt} />}
    </span>
  );
};

export const RemoveButton = (props) => {
  return <InlineIconButton {...props} src='/cross.svg' alt='Cross' />;
};

export const SaveButton = (props) => {
  return <InlineIconButton {...props} src='/save.svg' alt='Save' />;
};
