import { Form, Formik, useField } from 'formik';
import { saveAs } from 'file-saver';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useConfigApiClient } from '../../../../api';
import { getErrorMessage, useUser } from '../../../util';
import { Button, FormInput, FormSwitch, RemoveButton } from '../../../component';
import { ApiConnectionTestModal } from './ApiConnectionTestModal';
import { useSearchParams } from 'react-router-dom';

const initialConfigValues = {
  enabled: false,
  hostname: '',
  headers: [],
  searchParams: [],
  mtls: {
    enabled: false,
    certificate: '',
  },
  oauth: {
    enabled: false,
    url: '',
    aud: '',
    aggregatorClientId: '',
    signingAlgorithm: 'RS512',
    data: [],
  },
};

const removeTrailingNumbers = (id  = '') => {
  const parts = id.split('-');
  return isFinite(Number(parts.at(-1))) ? parts.slice(0, -1).join('-') : id;
};

const getJwksUrl = (clientId, env) => {
  return `https://publickey.patient-care-aggregator.com/${removeTrailingNumbers(clientId)}/${env.name}/.well_known/jwks.json`;
};

const removeEmptyKeyValues = (values) => {
  return values.filter((v) => v.name || v.value);
};

const KeyValuePairs = ({ label, name, addButtonText, onHelpClick }) => {
  const [field, , helper] = useField(name);

  const onAdd = () => {
    helper.setValue([...(field.value || []), { name: '', value: '' }]);
  };

  const onRemove = (i) => {
    const copy = [...field.value];
    copy.splice(i, 1);
    helper.setValue(copy);
  };

  return (
    <div className='mt-4 mb-3 d-flex flex-column'>
      <div className='mb-2 d-flex flex-row align-items-center'>
        <h6 className='text-muted mb-0 me-2'>{label}</h6>
        {onHelpClick && <img className='help' src='/help.svg' alt='Servita Logo' onClick={onHelpClick} />}
      </div>
      <div className='d-flex flex-column align-items-center'>
        {field.value?.length != null &&
          field.value.map((_, i) => (
            <div key={i} className='w-100 d-flex mb-1 align-items-center'>
              <FormInput name={`${name}.${i}.name`} className='me-1' placeholder='Name' />
              <FormInput name={`${name}.${i}.value`} className='ms-1' placeholder='Value' />
              <RemoveButton className='ms-2' size='sm' onClick={() => onRemove(i)} />
            </div>
          ))}
        <Button size='sm' className='key-value-add-btn' onClick={onAdd}>
          <b>+</b> {addButtonText}
        </Button>
      </div>
    </div>
  );
};

export const ApiConfigCard = ({
  env,
  values,
  clientId,
  onSaveComplete,
  clientDetailsFormikRef = { current: null },
  onDeleteClick,
  onHelpClick,
}) => {
  const user = useUser();
  const [searchParams, setSearchParams] = useSearchParams();

  const client = useConfigApiClient();
  const update = client.useUpdate();
  const csr = client.useCsr();

  const onSubmit = (/** @type {typeof initialConfigValues} */ data) => {
    const conf = {
      ...clientDetailsFormikRef.current?.values,
      env: env.name,
      hostname: data.hostname,
      enabled: data.enabled,
      headers: removeEmptyKeyValues(data.headers),
      searchParams: removeEmptyKeyValues(data.searchParams),
      mtls: data.mtls,
      oauth: {
        ...data.oauth,
        data: removeEmptyKeyValues(data.oauth.data),
      },
    };

    update
      .execute(conf)
      .then(({ data }) => {
        toast(`Configuration for ${env.label} has been updated`, { type: 'success' });
        onSaveComplete(data);
      })
      .catch((e) => {
        toast(`Error updating configuration: ${getErrorMessage(e)}`, { type: 'error' });
      });
  };

  const downloadCsr = () => {
    csr
      .execute({ env: env.name, clientId })
      .then(({ data }) => {
        saveAs(new Blob([data], { type: 'text/plain;charset=utf-8' }), `wayfinder-${env.name}.csr`);
      })
      .catch((e) => {
        toast(`Error downloading csr for ${env.label}: ${getErrorMessage(e)}`);
      });
  };

  const openTestConnectionModal = () => {
    setSearchParams({ env: env.name });
  };

  const closeTestConnectionModal = () => {
    setSearchParams({});
  };

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={{ ...initialConfigValues, ...values }}>
        <Form>
          <Card className='mb-3'>
            <Card.Header className='mb-0 d-flex flex-row align-items-center'>
              <Card.Title className='mb-0 me-2'>{env.label}</Card.Title>
              <FormSwitch name='enabled' />
              <div className='ms-auto'>
                {onDeleteClick && (
                  <Button size='sm' className='me-1' variant='outline-danger' onClick={onDeleteClick}>
                    Delete
                  </Button>
                )}
                <Button size='sm' className='me-1' variant='outline-success' onClick={openTestConnectionModal}>
                  Test Connection
                </Button>

                <Button size='sm' disabled={update.loading} type='submit'>
                  Save
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Tabs defaultActiveKey='general' id='uncontrolled-tab-example' className='mb-3'>
                <Tab eventKey='general' title='General'>
                  <FormInput name='hostname' containerClassName='mb-4' label='API URL' />
                  <KeyValuePairs
                    name='searchParams'
                    label='Query Parameters'
                    addButtonText='Add Parameter'
                    onHelpClick={onHelpClick}
                  />
                  <KeyValuePairs name='headers' label='Headers' addButtonText='Add Header' onHelpClick={onHelpClick} />
                </Tab>
                {user.meta.isAdmin && (env.downloadCsr || env.uploadCert) && (
                  <Tab eventKey='mtls' title='MTLS'>
                    {env.uploadCert && (
                      <>
                        <FormSwitch name='mtls.enabled' label='Enable MTLS' containerClassName='mb-2' />
                        <hr />
                      </>
                    )}
                    {env.downloadCsr && (
                      <Button className='w-100 mb-1' disabled={csr.loading} onClick={downloadCsr}>
                        Download CSR
                      </Button>
                    )}
                    {env.uploadCert && <FormInput name='mtls.certificate' label='MTLS Certificate' as='textarea' />}
                  </Tab>
                )}
                <Tab eventKey='oauth' title='Oauth'>
                  <FormSwitch name='oauth.enabled' label='Enable Oauth2' containerClassName='mb-2' />
                  <hr />
                  <div className='my-4'>
                    <h6>JWKS URL</h6>
                    <a href={getJwksUrl(clientId || user.clientDetails?.id, env)} target='_blank' rel='noreferrer'>
                      {getJwksUrl(clientId || user.clientDetails?.id, env)}
                    </a>
                  </div>
                  <FormInput name='oauth.url' containerClassName='mb-4' label='Oauth URL' />
                  <FormInput name='oauth.aud' containerClassName='mb-4' label='Audience (aud)' />
                  <FormInput name='oauth.aggregatorClientId' containerClassName='mb-4' label='Aggregator Client ID' />
                  <FormInput
                    name='oauth.signingAlgorithm'
                    containerClassName='mb-4'
                    type='select'
                    label='Signing Algorithm'
                  >
                    {['HS256', 'HS384', 'HS512', 'RS256', 'RS384', 'RS512', 'ES256', 'ES384', 'ES512'].map((v) => (
                      <option key={v} value={v} label={v} />
                    ))}
                  </FormInput>
                  <KeyValuePairs
                    name='oauth.data'
                    label='Custom values included in the request body'
                    addButtonText='Add Value'
                  />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Form>
      </Formik>
      <ApiConnectionTestModal
        show={searchParams.get('env') === env.name}
        onHide={closeTestConnectionModal}
        env={env}
        config={values}
        clientId={clientId}
      />
    </>
  );
};
