import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { createBoltApiClient } from './bolt';
import { createEvaApiClient } from './eva';
import { createUsersApiClient } from './users';
import { createConfigApiClient } from './config';
import { createAggregatorApiClient } from './aggregator';

// ------------------------------------------------------------------------------------------

/** @type {import('react').Context<ApiClients>} */
const ClientContext = React.createContext();

// ------------------------------------------------------------------------------------------

export const ClientProvider = (props) => {
  const auth0 = useAuth0();

  return (
    <ClientContext.Provider
      {...props}
      value={{
        eva: createEvaApiClient(auth0),
        bolt: createBoltApiClient(auth0),
        users: createUsersApiClient(auth0),
        config: createConfigApiClient(auth0),
        aggregator: createAggregatorApiClient(auth0),
      }}
    />
  );
};

// ------------------------------------------------------------------------------------------

export const useEvaApiClient = () => {
  return React.useContext(ClientContext)['eva'];
};

export const useBoltApiClient = () => {
  return React.useContext(ClientContext)['bolt'];
};

export const useUserApiClient = () => {
  return React.useContext(ClientContext)['users'];
};

export const useConfigApiClient = () => {
  return React.useContext(ClientContext)['config'];
};

export const useAggregatorApiClient = () => {
  return React.useContext(ClientContext)['aggregator'];
};

// ------------------------------------------------------------------------------------------

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 * @typedef {{
 *  bolt: ReturnType<typeof createBoltApiClient>,
 *  eva: ReturnType<typeof createEvaApiClient>,
 *  users: ReturnType<typeof createUsersApiClient>,
 *  config: ReturnType<typeof createConfigApiClient>
 *  aggregator: ReturnType<typeof createAggregatorApiClient>
 * }} ApiClients
 */
