import { Form, Formik } from 'formik';
import produce from 'immer';
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Table } from 'react-bootstrap';
import { useBoltApiClient } from '../../../api';
import { createNameFunction, getErrorMessage, useFormikRef, useModalControl } from '../../util';
import {
  Button,
  CardItemHeader,
  ConfirmationModal,
  FormInput,
  ListInput,
  Loading,
  Pagination,
  SubTitle,
  Title,
} from '../../component';
import { toast } from 'react-toastify';

const PAGE_SIZE = 10;

const TestCase = ({ index, name }) => {
  return (
    <div className='d-flex flex-row w-100'>
      <FormInput label='Name' name={name(index, 'name')} />
      <FormInput containerClassName='mx-1' type='number' label='Rate' name={name(index, 'rate')} />
      <FormInput label='Duration' type='number' name={name(index, 'duration')} />
    </div>
  );
};

const TestModal = ({ title, predatorTests, initialValues, show, loading, onHide, onSubmit }) => {
  const formik = useFormikRef();

  onHide = loading ? undefined : onHide;

  const setValues = (fn) => {
    formik.current.setValues(produce(formik.current.values, fn));
  };

  const onShow = () => {
    formik.current.setValues(initialValues);
  };

  const onAddTestCase = () => {
    setValues((draft) => {
      draft.cases.push({
        name: '',
        rate: 1,
        duration: 10,
      });
    });
  };

  const onRemoveTestCase = (i) => {
    setValues((draft) => {
      draft.cases.splice(i, 1);
    });
  };

  return (
    <Formik innerRef={formik} initialValues={initialValues}>
      {({ values }) => (
        <Form>
          <Modal size='lg' show={show} onShow={onShow} onHide={onHide}>
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormInput name='name' />
              <FormInput name='testId' label='Predator Test' type='select'>
                <option disabled selected>
                  Select an option...
                </option>
                {predatorTests.map((t, i) => (
                  <option key={i} label={t.name} value={t.id} />
                ))}
              </FormInput>
              <FormInput className='mb-1' as='textarea' name='description' label='Description' />
              <div className='text-muted mt-2 mb-2'>
                <h5>Test Cases</h5>
                <ListInput
                  items={values.cases}
                  component={TestCase}
                  name={createNameFunction('cases')}
                  onAdd={onAddTestCase}
                  onRemove={onRemoveTestCase}
                  addButtonLabel={
                    <Fragment>
                      <b>+</b>Add Test Case
                    </Fragment>
                  }
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button disabled={loading} onClick={onHide}>
                Cancel
              </Button>
              <Button disabled={loading} onClick={() => onSubmit(values)}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

const TestCard = ({ test, predatorTests, onEdit, onRemove }) => {
  return (
    <Col xs='12' md='6'>
      <Card className='mb-2'>
        <CardItemHeader title={test.name} onEditClick={onEdit} onRemoveClick={onRemove} />
        <Card.Body className='d-flex flex-column'>
          <SubTitle>Predator Test</SubTitle>
          <p>{predatorTests.find((t) => t.id === test.testId)?.name}</p>
          <SubTitle>Description</SubTitle>
          <p>{test.description}</p>
          <div className='text-muted mt-2 mb-2'>
            <SubTitle>Test Cases</SubTitle>
            <Table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Rate</td>
                  <td>Duration</td>
                </tr>
              </thead>
              <tbody>
                {test.cases.map((c, i) => (
                  <tr key={i}>
                    <td>{c.name}</td>
                    <td>{c.rate}</td>
                    <td>{c.duration}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export const TestsView = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [focusedIndex, setFocusedIndex] = useState(null);

  const createTestModal = useModalControl();
  const updateTestModal = useModalControl();
  const removeTestModal = useModalControl();

  const client = useBoltApiClient();
  const page = client.admin.test.usePage();
  const update = client.admin.test.useUpdate();
  const create = client.admin.test.useCreate();
  const destroy = client.admin.test.useDelete();
  const predatorTestList = client.predator.useList();

  const focused = page.data?.docs?.[focusedIndex];
  const predatorTests = predatorTestList.data || [];

  useEffect(() => {
    page.execute({ size: PAGE_SIZE, page: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    predatorTestList.execute();
  }, []);

  const refresh = () => {
    page.execute();
  };

  const onEdit = (i) => {
    setFocusedIndex(i);
    updateTestModal.open();
  };

  const onCreateTest = (values) => {
    create
      .execute(values)
      .then(() => {
        refresh();
        createTestModal.close();
        toast(`Test ${values.name} has been created`, { type: 'success' });
      })
      .catch((e) => {
        console.log(e.response);
        toast(`Error creating test ${values.name}: ${getErrorMessage(e)}`, { type: 'warning' });
      });
  };

  const onUpdateTest = (values) => {
    update
      .execute(values)
      .then(() => {
        refresh();
        updateTestModal.close();
        toast(`Test ${values.name} has been updated`, { type: 'success' });
      })
      .catch((e) => {
        toast(`Error updating test ${values.name}`, { type: 'warning' });
      });
  };

  const onRemoveTest = (i) => {
    setFocusedIndex(i);
    removeTestModal.open();
  };

  const onConfirmTestRemoval = () => {
    destroy
      .execute(focused.id)
      .then(() => {
        refresh();
        removeTestModal.close();
        toast(`Test has been deleted`, { type: 'success' });
      })
      .catch(() => {
        toast(`Error deleting test`, { type: 'warning' });
      });
  };

  if (page.loading || predatorTestList.loading) {
    return <Loading />;
  }

  if (page.error) {
    // @todo
    return <></>;
  }

  return (
    <>
      <div className='w-100 d-flex flex-column justify-content-center'>
        <Title text='Tests'>
          <Button size='sm' onClick={createTestModal.open}>
            <b>+</b>Create New Test
          </Button>
        </Title>
        <Row>
          {page.data?.docs.map((test, i) => (
            <TestCard
              key={i}
              test={test}
              name={createNameFunction(i)}
              onEdit={() => onEdit(i)}
              onRemove={() => onRemoveTest(i)}
              predatorTests={predatorTests}
            />
          ))}
        </Row>
        <Pagination
          loading={page.loading}
          page={pageIndex}
          size={PAGE_SIZE}
          total={page.data?.totalDocs}
          onPageChange={setPageIndex}
        />
      </div>
      <TestModal
        title='Create Test'
        show={createTestModal.isOpen}
        loading={create.loading}
        onHide={createTestModal.close}
        onSubmit={onCreateTest}
        predatorTests={predatorTests}
        initialValues={{
          name: `Test #${page.data?.totalDocs + 1}`,
          description: '',
          cases: [],
        }}
      />
      {focused && (
        <>
          <TestModal
            title={`Edit ${focused.name}`}
            show={updateTestModal.isOpen}
            loading={update.loading}
            onHide={updateTestModal.close}
            onSubmit={onUpdateTest}
            predatorTests={predatorTests}
            initialValues={focused}
          />
          <ConfirmationModal
            show={removeTestModal.isOpen}
            loading={destroy.loading}
            onHide={removeTestModal.close}
            onConfirm={onConfirmTestRemoval}
            title={`Remove Group ${focused.name}`}
            message={
              <span>
                Are you sure you want to <b className='text-danger'>remove</b> test {focused.name}?
              </span>
            }
          />
        </>
      )}
    </>
  );
};
