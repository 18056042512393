import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ClientProvider } from '../api';
import { AppLayout } from './layout';
import { ApiConfig, Bolt, Eva, MainView, NhsLogin, NotFoundView } from './view';

const AppRoutes = () => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_EVA_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_EVA_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_EVA_AUTH0_AUDIENCE}
      authorizeTimeoutInSeconds={5}
      redirectUri={window.location.origin}
      onRedirectCallback={(state) => navigate(state.returnTo || '/')}
    >
      <ClientProvider>
        <Routes>
          <Route element={<AppLayout />}>
            <Route index element={<MainView />} />
            <Route path='*' element={<NotFoundView />} />
            <Route path='bolt'>
              <Route index element={<Bolt.HomeView />} />
              <Route path='groups' element={<Bolt.GroupsView />} />
              <Route path='tests' element={<Bolt.TestsView />} />
              <Route path='test-result/:id' element={<Bolt.TestRunView />} />
            </Route>
            <Route path='eva'>
              <Route index element={<Eva.HomeView />} />
              <Route path='diff/:id' element={<Eva.DiffView />} />
              <Route path='run/:id' element={<Eva.TestRunView />} />
            </Route>
            <Route path='api-config'>
              <Route index element={<ApiConfig.HomeView />} />
            </Route>
            <Route path='nhs-login'>
              <Route index element={<NhsLogin.Login />} />
            </Route>
            <Route path='callback'>
              <Route index element={<NhsLogin.Callback />} />
            </Route>
          </Route>
        </Routes>
      </ClientProvider>
      <ToastContainer theme='colored' autoClose={7000} />
    </Auth0Provider>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default App;
