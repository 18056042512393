import { useEffect, useRef } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useBoltApiClient } from '../../../api';
import { Loading, SubTitle, Title } from '../../component';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as CjsTitle,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { friendlyString } from '../../util';

// ------------------------------------------------------------------------------------------

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, CjsTitle, Tooltip, Legend);

const getChartDataForReport = ({ intermediates }) => {
  return {
    labels: intermediates.map((i) => moment(i.timestamp).format('LTS')),
    datasets: [
      {
        label: 'P95',
        data: intermediates.map((i) => i.latency.p95),
        borderColor: '#c4e6d2',
        backgroundColor: '#c4e6d2',
        radius: 5,
        pointRadius: 5,
      },
      {
        label: 'P99',
        data: intermediates.map((i) => i.latency.p99),
        borderColor: '#ffe5b2',
        backgroundColor: '#ffe5b2',
        radius: 5,
        pointRadius: 5,
      },
      {
        label: 'Median',
        data: intermediates.map((i) => i.latency.median),
        borderColor: '#c5c4ec',
        backgroundColor: '#c5c4ec',
        radius: 5,
        pointRadius: 5,
      },
    ],
  };
};

// ------------------------------------------------------------------------------------------

const PerformanceItem = ({ label, value }) => {
  return (
    <Col xs='12' md='4'>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <h4 className={`pass-rate ${value <= 200 ? 'good' : value <= 300 ? 'medium' : 'bad'}`}>
          {value?.toFixed(1) || 'N/A'}
        </h4>
        <h5 className='text-muted'>
          <b>{label}</b>
        </h5>
      </div>
    </Col>
  );
};

const Latency = ({ values = {} }) => {
  return (
    <div className='w-100 mb-3'>
      <SubTitle>Latency</SubTitle>
      <Row className='mt-3'>
        <PerformanceItem label='P95' value={values?.p95} />
        <PerformanceItem label='P99' value={values?.p99} />
        <PerformanceItem label='Median' value={values?.median} />
      </Row>
    </div>
  );
};

// ------------------------------------------------------------------------------------------

const Details = ({ run, job, report }) => {
  return (
    <div className='w-100'>
      <SubTitle>Details</SubTitle>
      <div className='mb-4 d-flex flex-column'>
        <Table className='bolt-details-table'>
          <tbody>
            <tr>
              <td>Start Time</td>
              <td>{report?.start_time ? moment(report.start_time).format('LLL') : 'N/A'}</td>
            </tr>
            <tr>
              <td>Finish Time</td>
              <td>{report?.end_time ? moment(report.end_time).format('LLL') : 'N/A'}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{report?.status || friendlyString(run.status)}</td>
            </tr>
            <tr>
              <td>Duration</td>
              <td>{job.duration / 60} minutes</td>
            </tr>
            <tr>
              <td>Rate</td>
              <td>{job.arrival_rate} RPS</td>
            </tr>
            <tr>
              <td>Total Requests</td>
              <td>{report?.aggregate.requestsCompleted || 0}</td>
            </tr>
            <tr>
              <td>Response Codes</td>
              <td>
                {Object.entries(report?.aggregate.codes || {}).map(([code, count]) => (
                  <span className='me-1'>
                    {code}
                    <small>(x{count})</small>
                  </span>
                ))}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

// ------------------------------------------------------------------------------------------

const TimeGraph = ({ report }) => {
  return (
    <div className='w-100'>
      <SubTitle>Time Graph</SubTitle>
      {!report ? (
        <Loading animation='grow' />
      ) : (
        <div className='mb-4 chart-container'>
          <Line
            data={getChartDataForReport(report)}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                },
                tooltip: {
                  intersect: false,
                },
              },
              scales: {
                x: {
                  beginAtZero: false,
                  ticks: {
                    maxTicksLimit: 10,
                  },
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

// ------------------------------------------------------------------------------------------

const TestResultData = ({ run }) => {
  const timeout = useRef();

  const client = useBoltApiClient();
  const runData = client.test.useTestRunData();

  const fetchRunData = () => {
    runData.execute(run.id).then(({ data }) => {
      if (run.status === 'pending' || run.status === 'started' || data?.report.status === 'In progress') {
        timeout.current = setTimeout(fetchRunData, 10000);
      }
    });
  };

  useEffect(() => {
    fetchRunData();
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  if (!runData.data && runData.loading) {
    return <Loading />;
  }

  if (runData.error || !runData.data) {
    // @todo
    return <></>;
  }

  const { job, report } = runData.data;

  return (
    <div className='w-100'>
      <Latency values={report?.aggregate.latency} />
      <Details run={run} job={job} report={report} />
      <TimeGraph report={report} />
    </div>
  );
};

// ------------------------------------------------------------------------------------------

export const TestRunView = () => {
  const { id } = useParams();

  const client = useBoltApiClient();
  const run = client.test.useOneTestRun();

  useEffect(() => {
    run.execute(id);
  }, [id]);

  if (run.loading) {
    return <Loading />;
  }

  if (run.error || !run.data) {
    // @todo
    return <div></div>;
  }

  return (
    <div className='w-100'>
      <Title
        text={
          <span>
            <sup className='d-block'>Test run for:</sup>
            {run.data.testName} <small>({run.data.testCaseName})</small>
          </span>
        }
      />
      <Card className='mb-4 mx-0'>
        <Card.Body>
          <TestResultData run={run.data} />
        </Card.Body>
      </Card>
    </div>
  );
};
