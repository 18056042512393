import { Pagination as BsPagination } from 'react-bootstrap';

const PAGINATION_ITEMS = 5;

const getPaginationIndexes = (index, max) => {
  return [...Array(PAGINATION_ITEMS)]
    .map((_, i) => index + i - Math.floor(PAGINATION_ITEMS / 2))
    .map((p, i) => Math.min(Math.max(p, i + 1), max - (PAGINATION_ITEMS - 1) + i))
    .filter((p) => p > 0);
};

export const Pagination = ({ loading = false, page = 1, size = 20, total = 0, onPageChange }) => {
  const max = Math.ceil(total / size || 0);

  return (
    max > 1 && (
      <BsPagination className={`mt-4 d-flex justify-content-center ${loading ? 'loading' : ''}`}>
        <BsPagination.First disabled={loading || page === 1} onClick={() => onPageChange(1)} />
        <BsPagination.Prev disabled={loading || page <= 1} onClick={() => onPageChange(page - 1)} />
        {getPaginationIndexes(page, max).map((p) => (
          <BsPagination.Item key={p} active={p === page} onClick={() => onPageChange(p)}>
            {p}
          </BsPagination.Item>
        ))}
        <BsPagination.Next disabled={loading || page >= max} onClick={() => onPageChange(page + 1)} />
        <BsPagination.Last disabled={loading || page === max} onClick={() => onPageChange(max)} />
      </BsPagination>
    )
  );
};
