import jwtDecode from 'jwt-decode';
import { storage } from './storage';

export const getNhsLoginToken = () => {
  const value = storage.nhsLogin.idToken.get();

  if (!value) {
    return null;
  }

  const decoded = jwtDecode(value);

  return {
    value,
    nhsNumber: decoded.nhs_number,
  };
};
