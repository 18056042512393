import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Input, Loading } from '../component';
import { useUser } from '../util';
import { ResourceTypeProvider } from '../../context';

// ------------------------------------------------------------------------------------------

export const AppLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, meta, isAuthenticated, isLoading, error, loginWithRedirect, logout } = useUser();
  const [resourceType, setResourceType] = useState('appointments');

  useEffect(() => {
    if (!isLoading && !(isAuthenticated || error)) {
      loginWithRedirect({
        appState: {
          returnTo: `${location.pathname}${location.search || ''}`,
        },
      });
    }
  }, [isLoading, isAuthenticated, error, loginWithRedirect, location]);

  return (
    <>
      <div className='app-header'>
        <div className='left'>
          <Input type='select' label='Resource Type' value={resourceType} onChange={(e) => setResourceType(e.target.value)}>
            <option label='Appointments' value='appointments' />
            <option label='Documents' value='documents' />
            <option label='Questionnaires' value='questionnaires' />
          </Input>
        </div>
        <div className='center'>
          <img className='logo' src='/logo.svg' alt='Servita Logo' onClick={() => navigate('/')} />
        </div>
        <div className='right'>
          {user && (
            <Dropdown>
              <Dropdown.Toggle className='p-0' variant=''>
                <b>{user.nickname || user.name || user.email}</b>
              </Dropdown.Toggle>
              <Dropdown.Menu align='end' className='mt-3'>
                {meta.isAdmin && (
                  <>
                    <Dropdown.Item onClick={() => navigate('/bolt/groups')}>Bolt Groups</Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate('/bolt/tests')}>Bolt Tests</Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate('/nhs-login')}>NHS Login</Dropdown.Item>
                  </>
                )}
                <Dropdown.Item onClick={() => logout({ returnTo: window.origin })}>Sign out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      <div className='app-container'>
        <ResourceTypeProvider value={resourceType}>
          <main className='app-main'>{isLoading || !isAuthenticated ? <Loading /> : <Outlet />}</main>
        </ResourceTypeProvider>
      </div>
    </>
  );
};
