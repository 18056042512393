import { Form, Formik } from 'formik';
import { Card } from 'react-bootstrap';
import { FormInput } from '../../../component';

const initialClientDetailsValues = {
  name: '',
  clientId: '',
  mappingKey: '',
};

export const ClientDetailsCard = ({ innerRef }) => {
  return (
    <Formik innerRef={innerRef} initialValues={initialClientDetailsValues}>
      <Form>
        <Card className='mb-3'>
          <Card.Header className='mb-0 d-flex flex-row align-items-center'>
            <Card.Title className='mb-0'>Client Details</Card.Title>
          </Card.Header>
          <Card.Body>
            <FormInput containerClassName='mb-4' name='clientId' label='Client ID' />
            <FormInput containerClassName='mb-4' name='name' label='Name' />
            <FormInput containerClassName='mb-4' name='mappingKey' label='Mapping Key' />
            <FormInput containerClassName='mb-4' defaultValue={false} type='select' name='isErs' label='Is eRS API?'>
              <option value={false} label='No' />
              <option value={true} label='Yes' />
            </FormInput>
          </Card.Body>
        </Card>
      </Form>
    </Formik>
  );
};
