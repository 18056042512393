import { createUseResponseHook } from './util';

export const createConfigApiClient = (/** @type {import("@auth0/auth0-react").Auth0ContextInterface} */ auth0) => {
  const useResponse = createUseResponseHook(auth0, {
    baseURL: process.env.REACT_APP_CONFIG_API_HOST,
    addResourceTypeParam: true,
  });

  return {
    useAll: () =>
      useResponse(() => ({
        url: '/all',
        method: 'get',
      })),
    useCsr: () =>
      useResponse(({ env, clientId }) => ({
        url: '/csr',
        method: 'get',
        params: { env, clientId },
      })),
    useUpdate: () =>
      useResponse((data) => ({
        url: '/',
        method: 'post',
        data,
      })),
    useDestroy: () =>
      useResponse(({ env, clientId }) => ({
        url: '/', 
        method: 'delete',
        params: { env, clientId },
      })),
  };
};
