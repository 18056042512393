import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { storage } from '../util';

// ------------------------------------------------------------------------------------------

const getNhsLoginUrl = () => {
  const url = new URL('https://auth.aos.signin.nhs.uk/authorize');

  url.searchParams.append('scope', 'openid profile');
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('client_id', process.env.REACT_APP_NHS_LOGIN_CLIENT_ID);
  url.searchParams.append('redirect_uri', process.env.REACT_APP_NHS_LOGIN_REDIRECT_URI);

  return url.toString();
};

// ------------------------------------------------------------------------------------------

export const NhsLoginButton = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const onLogin = () => {
    window.location.href = getNhsLoginUrl();
    storage.nhsLogin.lastLocation.set(`${location.pathname}${location.search || ''}`);
    setLoading(true);
  };

  return (
    <img
      src='/nhs.svg'
      className='clickable'
      alt='NHS Logo'
      style={{ height: 70 }}
      disabled={loading}
      onClick={onLogin}
    />
  );
};
