import { useEffect, useState } from 'react';
import { Badge, Card, Modal, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvaApiClient } from '../../../api';
import { Button, Loading, Pagination } from '../../component';

// ------------------------------------------------------------------------------------------

const PAGE_SIZE = 20;

// ------------------------------------------------------------------------------------------

const IssuesModal = ({ show = false, onHide, result }) => {
  const [page, setPage] = useState(1);

  const client = useEvaApiClient();
  const issuePage = client.useTestResultIssuePage();

  useEffect(() => {
    if (show && result?.ID) {
      issuePage.execute({ id: result.ID, size: PAGE_SIZE, page });
    }
  }, [show, result?.ID, page]);

  useEffect(() => {
    if (!show) {
      setPage(0);
    }
  }, [show]);

  return (
    <Modal size='xl' show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Issues for {result.NHSNumber}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {issuePage.loading ? (
          <Loading />
        ) : result.Error ? (
          <div>
            <h4>Unexpected Error:</h4>
            <p>{result.Error}</p>
          </div>
        ) : issuePage.data?.total === 0 ? (
          <div>
            <h4 className='text-muted'>No Issues found</h4>
          </div>
        ) : (
          <>
            <div className='table-responsive mx-2'>
              <Table>
                <thead>
                  <tr>
                    <th>Priority</th>
                    <th>Issue Type</th>
                    <th>Location</th>
                    <th>Expected</th>
                    <th>Actual</th>
                  </tr>
                </thead>
                <tbody className='text-align-left'>
                  {issuePage.data?.items.map((item, i) => (
                    <tr key={i}>
                      <td>{item.Priority || 'N/A'}</td>
                      <td>{item.IssueType}</td>
                      <td>{item.PathToObject}</td>
                      <td>{item.ExpectedValue}</td>
                      <td>{item.ActualValue}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Pagination
              page={page}
              loading={issuePage.loading}
              size={PAGE_SIZE}
              total={issuePage.data?.total}
              onPageChange={setPage}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

// ------------------------------------------------------------------------------------------

export const TestRunView = () => {
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const client = useEvaApiClient();
  const resultPage = client.useTestResultPage();

  const selected = selectedId && resultPage.data?.items.find((res) => res.ID === selectedId);

  useEffect(() => {
    resultPage.execute({ id, page, size: PAGE_SIZE });
  }, [page, id]);

  const onDetailsClick = (id) => {
    setSelectedId(id);
    setShowDetails(true);
  };

  return (
    <>
      <div className='mb-4 w-100'>
        <Card>
          <Card.Header>Test Results</Card.Header>
          <Card.Body>
            {resultPage.loading ? (
              <Loading />
            ) : (
              <Table>
                <thead>
                  <th>Outcome</th>
                  <th>NHS Number</th>
                  <th>P1</th>
                  <th>P2</th>
                  <th>P3</th>
                  <th>Details</th>
                  <th>Json Diff</th>
                </thead>
                <tbody>
                  {resultPage.data?.items.map((item, i) => (
                    <tr>
                      <td>
                        <Badge className='status-badge' bg={item.Outcome === 'PASS' ? 'success' : 'danger'}>
                          {item.Outcome.substring(0, 1).toUpperCase() + item.Outcome.substring(1).toLowerCase()}
                        </Badge>
                      </td>
                      <td>{item.NHSNumber}</td>
                      <td>
                        <h6 className='m-0'>{item.P1Differences}</h6>
                      </td>
                      <td>
                        <h6 className='m-0'>{item.P2Differences}</h6>
                      </td>
                      <td>
                        <h6 className='m-0'>{item.P3Differences}</h6>
                      </td>
                      <td>
                        <Button size='sm' onClick={() => onDetailsClick(item.ID)}>
                          View Details
                        </Button>
                      </td>
                      <td>
                        <Button size='sm' disabled={!!item.Error} onClick={() => navigate(`/eva/diff/${item.ID}`)}>
                          View Diff
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Pagination page={page} size={PAGE_SIZE} total={resultPage.data?.total} onPageChange={setPage} />
          </Card.Body>
        </Card>
      </div>
      {selected && <IssuesModal show={showDetails} result={selected} onHide={() => setShowDetails(false)} />}
    </>
  );
};
