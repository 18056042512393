import { Form, Formik } from 'formik';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { Card, Modal, FormCheck } from 'react-bootstrap';

import {
  Button,
  Loading,
  Title,
  ConfirmationModal,
  CardItemHeader,
  FormInput,
  ListInput,
  Pagination,
} from '../../component';
import { useFormikRef, useModalControl } from '../../util';
import mock from '../../mock';
import { useBoltApiClient, useUserApiClient } from '../../../api';

// ------------------------------------------------------------------------------------------

const PAGE_SIZE = 10;

// ------------------------------------------------------------------------------------------

const NameItem = ({ item }) => {
  return <span>{item.name}</span>;
};

const UserItem = ({ item: id, users }) => {
  return <NameItem item={users.find((u) => u.user_id === id) || {}} />;
};

const TestItem = ({ item }) => {
  return <NameItem item={item} />;
};

// ------------------------------------------------------------------------------------------

const ItemSelectionModal = ({
  show,
  loading,
  items = [],
  selected: preSelected,
  pagination,
  title,
  onSelect,
  onHide,
  idProperty = 'id',
}) => {
  const [selected, setSelected] = useState([]);

  const onShow = () => {
    setSelected([...preSelected]);
  };

  const onChange = (index, state) => {
    if (state) {
      setSelected([...selected, items[index]]);
    } else {
      setSelected(selected.filter((item) => item[idProperty] !== items[index][idProperty]));
    }
  };

  return (
    <Modal size='lg' show={show} onShow={onShow} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <div className='ms-2'>
            {items.map((item, i) => (
              <FormCheck
                key={i}
                type='checkbox'
                label={item.name}
                checked={selected.some((s) => s[idProperty] === item[idProperty])}
                onChange={(e) => onChange(i, e.target.checked)}
              />
            ))}
          </div>
        )}
        {pagination && <Pagination loading={loading} {...pagination} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-danger' onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={() => onSelect(selected)}>Finish</Button>
      </Modal.Footer>
    </Modal>
  );
};

const TestSelectionModal = (props) => {
  const [pageIndex, setPageIndex] = useState(1);

  const client = useBoltApiClient();
  const page = client.admin.test.usePage();

  useEffect(() => {
    if (!page.data) {
      page.execute();
    }
  }, [props.show]);

  return (
    <ItemSelectionModal
      {...props}
      loading={page.loading}
      items={page.data?.docs}
      pagination={{
        page: pageIndex,
        size: PAGE_SIZE,
        total: page.data?.totalDocs,
        onPageChange: setPageIndex,
      }}
    />
  );
};

// ------------------------------------------------------------------------------------------

const GroupModal = ({ title, initialValues, show, loading, onHide, onSubmit, users }) => {
  const formik = useFormikRef();
  const addUsersModal = useModalControl();
  const addTestsModal = useModalControl();

  onHide = loading ? undefined : onHide;

  useEffect(() => {
    formik.current?.setValues(initialValues);
  }, [show]);

  const setValues = (fn) => {
    formik.current.setValues(produce(formik.current.values, fn));
  };

  const updateUsers = (users) => {
    addUsersModal.close();
    formik.current.setFieldValue(
      'users',
      users.map((u) => u.user_id)
    );
  };

  const updateTests = (tests) => {
    addTestsModal.close();
    formik.current.setFieldValue('tests', tests);
  };

  const removeUser = (i) => {
    setValues((draft) => {
      draft.users.splice(i, 1);
    });
  };

  const removeTest = (i) => {
    setValues((draft) => {
      draft.tests.splice(i, 1);
    });
  };

  return (
    <>
      <Formik innerRef={formik} initialValues={initialValues}>
        {({ values }) => (
          <Form>
            <Modal size='lg' show={show && !addUsersModal.isOpen && !addTestsModal.isOpen} onHide={onHide}>
              <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormInput name='name' label='Name' />
                <div className='mx-2'>
                  <div className='w-100 mt-4 mb-2'>
                    <h5>Users</h5>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <ListInput
                        items={values.users}
                        users={users}
                        component={UserItem}
                        onAdd={addUsersModal.open}
                        onRemove={removeUser}
                      />
                    </div>
                  </div>
                  <div className='w-100'>
                    <h5>Tests</h5>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <ListInput
                        items={values.tests}
                        component={TestItem}
                        onAdd={addTestsModal.open}
                        onRemove={removeTest}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='outline-danger' disabled={loading} onClick={onHide}>
                  Cancel
                </Button>
                <Button disabled={loading} onClick={() => onSubmit(values)}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
      {formik.current && (
        <>
          <ItemSelectionModal
            title={`Add users to group: ${initialValues.name}`}
            items={users}
            show={addUsersModal.isOpen}
            onHide={addUsersModal.close}
            selected={formik.current.values.users.map((id) => users.find((u) => u.user_id === id))}
            component={NameItem}
            onSelect={updateUsers}
            idProperty='user_id'
          />
          <TestSelectionModal
            title={`Add tests to group: ${initialValues.name}`}
            items={mock.tests}
            show={addTestsModal.isOpen}
            onHide={addTestsModal.close}
            selected={formik.current.values.tests}
            component={NameItem}
            onSelect={updateTests}
          />
        </>
      )}
    </>
  );
};

// ------------------------------------------------------------------------------------------

const GroupCard = ({ group, onEdit, onRemove, users }) => {
  return (
    <Card className='mb-2'>
      <CardItemHeader title={group.name} onEditClick={onEdit} onRemoveClick={onRemove} />
      <Card.Body className='d-flex flex-row'>
        <div className='w-100'>
          <h5>Users</h5>
          <div className='ms-1 d-flex flex-column'>
            {group.users.map((u, i) => (
              <UserItem key={i} item={u} users={users} />
            ))}
          </div>
        </div>
        <div className='w-100'>
          <h5>Tests</h5>
          <div className='ms-1 d-flex flex-column'>
            {group.tests.map((t, i) => (
              <TestItem key={i} item={t} />
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const GroupsView = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [focusedIndex, setFocusedIndex] = useState(null);

  const createGroupModal = useModalControl();
  const updateGroupModal = useModalControl();
  const removeGroupModal = useModalControl();

  const boltApi = useBoltApiClient();
  const userApi = useUserApiClient();

  const page = boltApi.admin.group.usePage();
  const update = boltApi.admin.group.useUpdate();
  const create = boltApi.admin.group.useCreate();
  const destroy = boltApi.admin.group.useDelete();
  const users = userApi.useAll();

  const focused = page.data?.docs?.[focusedIndex];

  useEffect(() => {
    page.execute({ size: PAGE_SIZE, page: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    if (!users.data) {
      users.execute();
    }
  }, []);

  const refresh = () => {
    page.execute();
  };

  const onEdit = (i) => {
    setFocusedIndex(i);
    updateGroupModal.open();
  };

  const onCreateGroup = (values) => {
    create.execute(values).then(() => {
      refresh();
      createGroupModal.close();
    });
  };

  const onUpdateGroup = (values) => {
    update.execute(values).then(() => {
      refresh();
      updateGroupModal.close();
    });
  };

  const onRemoveGroup = (i) => {
    setFocusedIndex(i);
    removeGroupModal.open();
  };

  const onConfirmGroupRemoval = () => {
    destroy.execute(focused.id).then(() => {
      refresh();
      removeGroupModal.close();
    });
  };

  if (page.loading || users.loading) {
    return <Loading />;
  }

  if (page.error || users.error) {
    // @todo
    return <></>;
  }

  return (
    <>
      <div className='w-100 d-flex flex-column justify-content-center'>
        <Title text='User Groups'>
          <Button size='sm' onClick={createGroupModal.open}>
            <b>+</b>Create New Group
          </Button>
        </Title>
        {page.data?.docs.map((group, i) => (
          <GroupCard
            key={i}
            group={group}
            users={users.data || []}
            onEdit={() => onEdit(i)}
            onRemove={() => onRemoveGroup(i)}
          />
        ))}
        <Pagination
          loading={page.loading}
          page={pageIndex}
          size={PAGE_SIZE}
          total={page.data?.totalDocs}
          onPageChange={setPageIndex}
        />
      </div>
      <GroupModal
        title='Create Group'
        show={createGroupModal.isOpen}
        users={users.data || []}
        loading={create.loading}
        onHide={createGroupModal.close}
        onSubmit={onCreateGroup}
        initialValues={{
          name: `Group #${page.data?.totalDocs + 1}`,
          users: [],
          tests: [],
        }}
      />
      {focused != null && (
        <>
          <GroupModal
            title={`Edit ${focused.name}`}
            show={updateGroupModal.isOpen}
            users={users.data || []}
            loading={update.loading}
            onHide={updateGroupModal.close}
            onSubmit={onUpdateGroup}
            initialValues={focused}
          />
          <ConfirmationModal
            show={removeGroupModal.isOpen}
            loading={destroy.loading}
            onHide={removeGroupModal.close}
            onConfirm={onConfirmGroupRemoval}
            title={`Remove Group ${focused.name}`}
            message={
              <span>
                Are you sure you want to <b className='text-danger'>remove</b> group {focused.name}?
              </span>
            }
          />
        </>
      )}
    </>
  );
};
