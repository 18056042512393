import React, { useState } from 'react';
import { RemoveButton } from './InlineIconButton';

export const Removable = ({ onRemoveClick, children, ...rest }) => {
  const [active, setActive] = useState(false);

  return (
    <div
      className='w-100 d-flex flex-row align-items-center'
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {children}
      <RemoveButton className='ml-auto mx-1' active={active} onClick={active ? onRemoveClick : undefined} />
    </div>
  );
};
