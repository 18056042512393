import { createUseResponseHook } from './util';

export const createEvaApiClient = (/** @type {import("@auth0/auth0-react").Auth0ContextInterface} */ auth0) => {
  const useResponse = createUseResponseHook(auth0, {
    baseURL: process.env.REACT_APP_EVA_API_HOST,
    addResourceTypeParam: true,
  });

  return {
    useNhsLogin: () => useResponse(({code, redirectUri}) => ({
      url: 'nhsLogin',
      method: 'post',
      params: {code, redirectUri}
    })),

    useTestSets: () =>
      useResponse({
        url: 'testSets',
        method: 'get',
      }),

    useTestRunPage: () =>
      useResponse((pagination) => ({
        url: 'test/page',
        method: 'get',
        params: pagination,
      })),

    useOneTestResult: () =>
      useResponse((id) => ({
        url: 'test/result',
        method: 'get',
        params: { id },
      })),

    useTestResultPage: () =>
      useResponse((params) => ({
        url: 'test/result/page',
        method: 'get',
        params,
      })),

    useTestResultIssuePage: () =>
      useResponse((params) => ({
        url: 'test/result/issue/page',
        method: 'get',
        params,
      })),

    useTestRunner: () =>
      useResponse((data) => ({
        url: 'test/run',
        method: 'post',
        data,
      })),

    useConfig: () =>
      useResponse({
        url: 'config',
        method: 'get',
      }),

    useConfigUpdate: () =>
      useResponse((data) => ({
        url: 'config',
        method: 'post',
        data,
      })),
  };
};
