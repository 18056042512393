import { createUseResponseHook } from './util';

export const createBoltApiClient = (/** @type {import("@auth0/auth0-react").Auth0ContextInterface} */ auth0) => {
  const useResponse = createUseResponseHook(auth0, {
    baseURL: process.env.REACT_APP_BOLT_API_HOST,
  });

  return {
    test: {
      useAll: () =>
        useResponse({
          url: '/test/all',
          method: 'get',
        }),
      useOneTestRun: () =>
        useResponse((id) => ({
          url: `/test/run/${id}`,
          method: 'get',
        })),
      useTestRunPage: () =>
        useResponse(({ page = 1, size: limit = 20 } = {}) => ({
          url: '/test/run',
          method: 'get',
          params: { page, limit },
        })),
      useTestRunData: () =>
        useResponse((id) => ({
          url: `/test/run/${id}/data`,
          method: 'get',
        })),
      useStart: () =>
        useResponse(({ id, caseId }) => ({
          url: `/test/start/${id}/${caseId}`,
          method: 'post',
        })),
    },
    predator: {
      useList: () =>
        useResponse({
          url: '/predator/test/all',
          method: 'get',
        }),
    },
    admin: {
      group: {
        useOne: () =>
          useResponse((id) => ({
            url: `/admin/group/${id}`,
            method: 'get',
          })),

        usePage: () =>
          useResponse(({ page = 1, size: limit = 20 } = {}) => ({
            url: '/admin/group',
            method: 'get',
            params: { page, limit },
          })),

        useCreate: () =>
          useResponse(({ tests, ...rest }) => ({
            url: '/admin/group',
            method: 'post',
            data: {
              tests: tests.map((t) => t.id),
              ...rest,
            },
          })),

        useUpdate: () =>
          useResponse(({ id, tests, ...rest }) => ({
            url: `/admin/group/${id}`,
            method: 'patch',
            data: {
              tests: tests.map((t) => t.id),
              ...rest,
            },
          })),

        useDelete: () =>
          useResponse((id) => ({
            url: `/admin/group/${id}`,
            method: 'delete',
          })),
      },
      test: {
        useOne: () =>
          useResponse((id) => ({
            url: `/admin/test/${id}`,
            method: 'get',
          })),

        usePage: () =>
          useResponse(({ page = 1, size: limit = 20 } = {}) => ({
            url: '/admin/test',
            method: 'get',
            params: { page, limit },
          })),

        useCreate: () =>
          useResponse((data) => ({
            url: '/admin/test',
            method: 'post',
            data,
          })),

        useUpdate: () =>
          useResponse(({ id, ...data }) => ({
            url: `/admin/test/${id}`,
            method: 'patch',
            data,
          })),

        useDelete: () =>
          useResponse((id) => ({
            url: `/admin/test/${id}`,
            method: 'delete',
          })),
      },
    },
  };
};
