import { createUseResponseHook } from './util';

export const createUsersApiClient = (/** @type {import("@auth0/auth0-react").Auth0ContextInterface} */ auth0) => {
  const useResponse = createUseResponseHook(auth0, {
    baseURL: process.env.REACT_APP_USERS_API_HOST,
  });

  return {
    useAll: () =>
      useResponse(() => ({
        url: '/users/all',
        method: 'get',
      })),
  };
};
