import { useAuth0 } from '@auth0/auth0-react';
import { useRef, useState } from 'react';

export * from './storage';
export * from './nhs';

// ------------------------------------------------------------------------------------------

const namespace = 'https://servita-apps.com';

export const useUser = () => {
  const auth0 = useAuth0();
  const roles = auth0.user?.[`${namespace}/roles`]?.map((s) => s.toLowerCase()) || []; 

  return {
    ...auth0,
    clientDetails: auth0.user?.["https://servita-apps.com/client"],
    meta: {
      isAdmin: roles.includes('admin'),
      isInternalUser: roles.includes('internal'),
    }
  };
};

// ------------------------------------------------------------------------------------------

export const byId = (items, id) => {
  return items.find((u) => u.id === id);
};

/**
 * @returns {import('react').RefObject<import('formik').FormikProps<any>>}
 */
export const useFormikRef = () => {
  return useRef(null);
};

export const useModalControl = (isOpen = false) => {
  const [open, setOpen] = useState(isOpen);

  return {
    isOpen: open,
    open: () => setOpen(true),
    close: () => setOpen(false),
  };
};

/**
 * @template T
 * @param {T} value
 * @returns {T}
 */
export const deepCopy = (value) => {
  return JSON.parse(JSON.stringify(value));
};

export const createNameFunction = (prefix) => {
  return (...path) => `${prefix}.${path.join('.')}`;
};

export const getErrorMessage = (e) => {
  return typeof e?.response?.data?.data === 'string' ? e.response.data.data : '';
};

export const friendlyString = (str = '') => {
  return str
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
    .join(' ');
};
