import { createUseResponseHook } from './util';

const getBaseUrl = (env) => {
  const url = process.env[`REACT_APP_AGGREGATOR_API_HOST_${env.toUpperCase()}`];

  if (url == null) {
    throw new Error(`invalid env: ${env}`);
  }

  return url;
};

export const createAggregatorApiClient = (auth0) => {
  const useResponse = createUseResponseHook();

  return {
    useDebugResponse: () =>
      useResponse(({ env, clientId, nhsNumber, token }) => ({
        baseURL: getBaseUrl(env),
        url: 'events',
        method: 'get',
        params: {
          'debug': true,
          'useConfigApi': true,
          'clientId': clientId || auth0.user['https://servita-apps.com/client']?.id,
          'patient:identifier': `https://fhir.nhs.uk/Id/nhs-number|${nhsNumber}`,
        },
        headers: {
          'X-Correlation-ID': `eva-config-${Math.round(Math.random() * 100_000_000)}`,
          ...(token && { 'NHSD-ID-Token': token }),
        },
      })),
  };
};
