import { createContext, useContext } from 'react';


/** @type {import('react').Context<'appointments' | 'documents' | 'questionnaires'>} */
const ResourceTypeContext = createContext('appointments');
export const ResourceTypeProvider = ResourceTypeContext.Provider;

export const useActiveResourceType = () => {
  return useContext(ResourceTypeContext);
};
