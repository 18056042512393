import {Button} from './Button';
import { Modal } from 'react-bootstrap';

export const ConfirmationModal = ({ show, loading, title, message, onConfirm, onHide }) => {
  return (
    <Modal show={loading || show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
        <Button disabled={loading} variant='outline-danger' onClick={onConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
