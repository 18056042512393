import * as Diff2Html from 'diff2html';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useEvaApiClient } from '../../../api';
import { Loading } from '../../component';

export const DiffView = () => {
  const { id } = useParams();

  const client = useEvaApiClient();
  const testResult = client.useOneTestResult();

  useEffect(() => {
    testResult.execute(id);
  }, [id]);

  if (testResult.loading) {
    return <Loading />;
  }

  const diffString = testResult.data?.DiffString;
  if (!diffString) {
    return <></>;
  }

  return (
    <div
    className='w-100 h-100'
      dangerouslySetInnerHTML={{
        __html: Diff2Html.html(diffString, { outputFormat: 'side-by-side', drawFileList: false }),
      }}
    />
  );
};
