import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Card, ProgressBar, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEvaApiClient } from '../../../api';
import { Button, FormInput, Loading, Pagination } from '../../component';
import { friendlyString } from '../../util';
import { useActiveResourceType } from '../../../context';

// ------------------------------------------------------------------------------------------

const PAGE_SIZE = 20;

const toPercentageString = (x, y) => {
  return `${Math.floor((x / y) * 100)}%`;
};

const PassRate = ({ item: { Passes, Total } }) => {
  const rate = Passes / Total;
  return (
    <h6 className={`pass-rate ${rate <= 0.5 ? 'bad' : rate < 1 ? 'medium' : 'good'}`}>
      {toPercentageString(Passes, Total)}
    </h6>
  );
};

// ------------------------------------------------------------------------------------------

const downloadUrls = {
  'appointments': 'https://servita-test-data-pack.s3.eu-west-2.amazonaws.com/ServitaTestPack009_Generic_Appointment.zip',
  'documents': 'https://servita-test-data-pack.s3.eu-west-2.amazonaws.com/ServitaTestPack010_Generic_DocumentReference.zip',
  'questionnaires': 'https://servita-test-data-pack.s3.eu-west-2.amazonaws.com/ServitaTestPack010_Generic_Questionnaire.zip'
}

const firstCharUppercase = (/** @type {string} */ str) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
}

const TestPackDownload = () => {
  const type = useActiveResourceType();

  return (
    <Card className='mb-4'>
      <Card.Header>Test Pack Download</Card.Header>
      <Card.Body>
        <p>
          Before running any tests, you'll need to configure your API and ingest all test pack data. To download the
          test pack data, click the button below:
        </p>
        <a href={downloadUrls[type]}>
          <Button className='w-100'>Download {firstCharUppercase(type)} Test Pack</Button>
        </a>
      </Card.Body>
    </Card>
  );
};

// ------------------------------------------------------------------------------------------

const PreviousRunsCard = ({ testRunPage, page, onPageChange }) => {
  const navigate = useNavigate();

  return (
    <Card className='mb-4'>
      <Card.Header>Previous Runs</Card.Header>
      <Card.Body className='d-flex flex-column align-items-center justify-content-center'>
        {testRunPage.loading && !testRunPage.data?.items?.length ? (
          <Loading />
        ) : testRunPage.data?.items.length ? (
          <Table striped>
            <thead>
              <tr>
                <th>Status</th>
                <th>Pass Rate</th>
                <th>Set</th>
                <th>Time/Date</th>
              </tr>
            </thead>
            <tbody>
              {testRunPage.data?.items.map((item, i) => (
                <tr key={i} className='clickable' onClick={() => navigate(`run/${item.ID}`)}>
                  <td>
                    {item.Status === 'RUNNING' ? (
                      <ProgressBar
                        animated
                        className='w-100'
                        now={(item.Processed / item.Total) * 100}
                        label={toPercentageString(item.Processed, item.Total)}
                      />
                    ) : (
                      <Badge
                        className='status-badge'
                        bg={
                          item.Status === 'RUNNING'
                            ? 'info'
                            : ['CANCELLED', 'ERROR'].includes(item.Status)
                            ? 'danger'
                            : 'success'
                        }
                      >
                        {friendlyString(item.Status)}
                      </Badge>
                    )}
                  </td>
                  <td>
                    <PassRate item={item} />
                  </td>
                  <td>
                    <h6 className='m-0'>{item.TestSet}</h6>
                  </td>
                  <td>
                    <span>{moment(item.RunDate).format('LLL')}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className='d-flex justify-content-center align-items-center'>
            <h4 className='text-muted'>No Previous Runs</h4>
          </div>
        )}
        <Pagination
          page={page}
          size={PAGE_SIZE}
          total={testRunPage.data?.total || 0}
          loading={testRunPage.loading}
          onPageChange={onPageChange}
        />
        <br />
      </Card.Body>
    </Card>
  );
};

// ------------------------------------------------------------------------------------------

const TestRunCard = ({ testSets, testRunner, onSubmitTestRun }) => {
  const navigate = useNavigate();

  return (
    <Card className='mb-4'>
      <Card.Header>Test Run</Card.Header>
      <Card.Body>
        {testSets.loading ? (
          <Loading />
        ) : (
          <Formik
            onSubmit={onSubmitTestRun}
            initialValues={{
              set: testSets[0] || 'custom',
              nhsNumbers: '',
            }}
          >
            {({ values }) => (
              <Form>
                <FormInput type='select' name='set'>
                  {testSets.data?.map((set, i) => (
                    <option key={i} value={set} label={set} />
                  ))}
                  <option label='Custom' value={'custom'} />
                </FormInput>
                {values.set === 'custom' && (
                  <FormInput className='mt-2' placeholder='NHS Numbers (CSV)' name='nhsNumbers' as='textarea' />
                )}
                <Button className='mt-2 w-100' type='submit' disabled={testRunner.loading}>
                  Start Test
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

// ------------------------------------------------------------------------------------------

export const HomeView = () => {
  const [page, setPage] = useState(1);

  const client = useEvaApiClient();
  const testSets = client.useTestSets();
  const testRunner = client.useTestRunner();
  const testRunPage = client.useTestRunPage();

  const fetchHistory = async () => {
    const { data } = await testRunPage.execute({ size: PAGE_SIZE, page });

    if (data?.items.some((h) => h.Status === 'RUNNING')) {
      setTimeout(fetchHistory, 3_000);
    }
  };

  useEffect(() => {
    testSets.execute().then(({ data }) => data?.sort());
  }, []);

  useEffect(() => {
    fetchHistory();
  }, [page]);

  const onSubmitTestRun = (values) => {
    const nhsNumbers = (values.nhsNumbers || '')
      .split('\n')
      .flatMap((s) => s.split(','))
      .map((s) => s.trim())
      .filter(Boolean);

    testRunner
      .execute({
        set: values.set,
        nhsNumbers,
      })
      .then(() => {
        fetchHistory();
      });
  };

  return (
    <div className='w-100'>
      <TestPackDownload />
      <TestRunCard testSets={testSets} testRunner={testRunner} onSubmitTestRun={onSubmitTestRun} />
      <PreviousRunsCard testRunPage={testRunPage} page={page} onPageChange={setPage} />
    </div>
  );
};
