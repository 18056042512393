import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEvaApiClient } from '../../../api';
import { storage } from '../../util';
import { Loading } from '../../component';

export const Callback = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const eva = useEvaApiClient();
  const nhsLogin = eva.useNhsLogin();

  useEffect(() => {
    const code = params.get('code');

    if (!code) {
      return navigate('/');
    }

    nhsLogin
      .execute({ code, redirectUri: process.env.REACT_APP_NHS_LOGIN_REDIRECT_URI })
      .then((res) => {
        storage.nhsLogin.idToken.set(res.data.token);
        navigate(storage.nhsLogin.lastLocation.getAndRemove() || '/');
      })
      .catch(() => {
        navigate('/')
      });
  }, []);

  return <Loading />;
};
