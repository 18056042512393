import { Modal, Table } from 'react-bootstrap';

export const RuntimeTokensHelpModal = ({ show, onHide }) => {
  return (
    <Modal size='xl' show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Using Runtime Tokens</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-3'>
        <p>
          Some variables are accessible at runtime and can be injected into both query parameters and headers by
          enclosing the token in curly braces <code>{'{}'}</code>. For example, to forward the NHS number received by the
          Aggregator to your API, a query parameter may have its name set to <code>patient:identifier</code> and value{' '}
          <code>{'https://fhir.nhs.uk/Id/nhs-number|{input.nhsNumber}'}</code>
        </p>
        <p>Available runtime tokens:</p>
        <Table className='runtime-tokens-table'>
          <thead>
            <tr>
              <td>Name</td>
              <td>Description</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>input.nhsNumber</code>
              </td>
              <td>
                The 10 digit NHS number received as query by the Aggregator. E.g. <code>9876543210</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>headers.x-correlation-id</code>
              </td>
              <td>
                The <code>X-Correlation-ID</code> header received by the Aggregator.
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};
