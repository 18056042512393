export const Title = ({ text, children }) => {
  return (
    <div className='w-100'>
      <div className='d-flex flex-row align-items-center'>
        <h4 className="mb-0">{text}</h4>
        <div className='ms-auto d-flex flex-row'>{children}</div>
      </div>
      <hr className='mt-2' />
    </div>
  );
};

export const SubTitle = ({ children }) => {
  return (
    <div className='w-100'>
      <h5 className='text-muted'>{children}</h5>
    </div>
  );
};
