import moment from 'moment';
import { useEffect, useState } from 'react';
import { Badge, Card, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useBoltApiClient } from '../../../api';
import { Button, Input, Loading } from '../../component';
import { friendlyString, getErrorMessage } from '../../util';

const TestRunCard = ({ tests, onSubmit, loading }) => {
  const [testIdx, setTestIdx] = useState();
  const [testCaseIdx, setTestCaseIdx] = useState();

  const test = tests.data?.[testIdx];
  const testCase = test?.cases[testCaseIdx];

  const submit = () => {
    if (test != null) {
      onSubmit(test, testCase);
    }
  };

  const onTestChange = (e) => {
    setTestIdx(e.target.value);
    setTestCaseIdx(undefined);
  };

  const onTestCaseChange = (e) => {
    setTestCaseIdx(e.target.value);
  };

  return (
    <Card className='mb-4'>
      <Card.Header>Test Run</Card.Header>
      <Card.Body>
        {tests.loading ? (
          <Loading />
        ) : (
          <>
            <Input type='select' value={testIdx} defaultValue='default' onChange={onTestChange}>
              <option disabled value='default'>
                Select a test to run...
              </option>
              {tests.data?.map((t, i) => (
                <option key={i} value={i} label={t.name} />
              ))}
            </Input>
            <Input
              type='select'
              disabled={test == null}
              defaultValue='default'
              value={testCaseIdx}
              onChange={onTestCaseChange}
            >
              <option disabled value='default'>
                Select a test case...
              </option>
              {test?.cases.map((t, i) => (
                <option key={i} value={i} label={`${t.name} (${t.duration} mins, ${t.rate} RPS)`} />
              ))}
            </Input>
            {test?.description && (
              <div className='mt-4'>
                <>
                  <h6 className='text-muted'>Test Description</h6>
                  <p className='ms-2'>{test.description}</p>
                </>
              </div>
            )}
            <Button className='me-1 mt-2 w-100' disabled={loading || !test || !testCase} onClick={submit}>
              Start Test
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

const PreviousRunsCard = ({ page }) => {
  const navigate = useNavigate();

  useEffect(() => {
    page.execute();
  }, []);

  return (
    <Card className='mb-4'>
      <Card.Header>Previous Runs</Card.Header>
      <Card.Body>
        {page.loading ? (
          <Loading />
        ) : (
          <Table striped>
            <thead>
              <tr>
                <td>Status</td>
                <td>Time/Date</td>
                <td>Test</td>
              </tr>
            </thead>
            <tbody>
              {page.data?.docs.map((run, i) => (
                <tr key={i} className='clickable' onClick={() => navigate(`./test-result/${run.id}`)}>
                  <td>
                    <Badge
                      className='status-badge'
                      bg={
                        run.status === 'finished'
                          ? 'success'
                          : ['aborted', 'failed', 'error'].includes(run.status)
                          ? 'danger'
                          : 'info'
                      }
                    >
                      {friendlyString(run.status)}
                    </Badge>
                  </td>
                  <td>{moment(run.createdAt).format('LLL')}</td>
                  <td>
                    {run.testName} <small>({run.testCaseName})</small>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
};

export const HomeView = () => {
  const client = useBoltApiClient();
  const tests = client.test.useAll();
  const page = client.test.useTestRunPage();
  const start = client.test.useStart();

  const refresh = () => {
    page.execute();
  };

  const onStartTest = (t, ts) => {
    start
      .execute({ id: t.id, caseId: ts.id })
      .then(() => {
        toast(`Test ${t.name} is running`, { type: 'success' });
        refresh();
      })
      .catch((e) => {
        toast(`Failed to start test: ${getErrorMessage(e)}`, { type: 'warning' });
      });
  };

  useEffect(() => {
    tests.execute();
    refresh();
  }, []);

  return (
    <div className='w-100'>
      <TestRunCard tests={tests} loading={start.loading} onSubmit={onStartTest} />
      <PreviousRunsCard page={page} />
    </div>
  );
};
