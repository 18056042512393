const item = (key) => {
  return {
    get() {
      return localStorage.getItem(key);
    },

    set(value) {
      localStorage.setItem(key, value);
    },

    getAndRemove() {
      const value = this.get();
      localStorage.removeItem(key);
      return value;
    },
  };
};

export const storage = {
  nhsLogin: {
    idToken: item('nhsLogin.idToken'),
    lastLocation: item('nhsLogin.lastLocation'),
  },
};
