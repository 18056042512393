import { Card } from 'react-bootstrap';
import { NhsLoginButton } from '../../component';
import { getNhsLoginToken } from '../../util';

const TokenInfo = (/** @type {{token: ReturnType<typeof getNhsLoginToken>}} */ { token }) => {
  return (
    <Card className='w-100 mb-3'>
      <Card.Header>
        <Card.Title>Token Info</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className='mb-2'>
          <h6>NHS Number</h6>
          <code>{token.nhsNumber}</code>
        </div>
        <div className='mb-2'>
          <h6>Token</h6>
          <code>{token.value}</code>
        </div>
      </Card.Body>
    </Card>
  );
};

const LoginCard = ({ signedIn }) => {
  return (
    <Card className='w-100 mb-3'>
      <Card.Header>
        <Card.Title>NHS Login</Card.Title>
      </Card.Header>
      <Card.Body className='d-flex flex-column align-items-center'>
        <p>
          {signedIn
            ? 'To re-generate a token or to change user, use NHS Login:'
            : 'You are not signed in. In order to generate an NHSD-ID-Token, please sign in using NHS Login:'}
        </p>
        <NhsLoginButton />
      </Card.Body>
    </Card>
  );
};

export const Login = () => {
  const token = getNhsLoginToken();

  return (
    <div className='d-flex flex-column w-75'>
      {token && <TokenInfo token={token} />}
      <LoginCard signedIn={!!token} />
    </div>
  );
};
