import ReactJson from 'react-json-view';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useAggregatorApiClient } from '../../../../api';
import { Button, Input, Loading, NhsLoginButton } from '../../../component';
import { getNhsLoginToken } from '../../../util';
import { useActiveResourceType } from '../../../../context';

const ResponseData = ({ data }) => {
  return typeof data === 'object' ? (
    <ReactJson name={false} displayDataTypes={false} enableClipboard={false} src={data} />
  ) : typeof data === 'string' ? (
    <div className='response-html-container' dangerouslySetInnerHTML={{ __html: data }} />
  ) : (
    <h6 className='text-muted'>No response data received</h6>
  );
};

export const ApiConnectionTestModal = ({ show, env, clientId, onHide }) => {
  const [nhsNumber, setNhsNumber] = useState('');

  const client = useAggregatorApiClient();
  const response = client.useDebugResponse();
  const token = getNhsLoginToken();
  const resourceType = useActiveResourceType();

  const onNhsNumberChange = (e) => {
    setNhsNumber(e.target.value);
  };

  const sendRequest = () => {
    response.execute(
      env.name === 'aos'
        ? {
            env: env.name,
            token: token.value,
            nhsNumber: token.nhsNumber,
            clientId: `${clientId}-${resourceType}`,
          }
        : {
            nhsNumber,
            env: env.name,
            clientId: `${clientId}-${resourceType}`,
          }
    );
  };

  useEffect(() => {
    if (show) {
      response.clear();
    }
  }, [show]);

  const fhirValidationErrors =
    (response.data?.fhirValidation?.valid === false &&
      response.data?.fhirValidation?.messages.filter((m) => m.severity === 'fatal' || m.severity === 'error')) ||
    [];

  return (
    <Modal className='test-connection-modal' centered size='xl' show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div>
          <div>Connection Test:</div>
          <Modal.Title>{env.label}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {env.name === 'aos' ? (
          <div className='d-flex flex-column align-items-center' style={{ wordBreak: 'break-all' }}>
            <div className='mb-4 d-flex flex-row align-items-center max-w-100'>
              {token && (
                <div className='d-flex flex-column me-3'>
                  <div className='mb-3'>
                    <h6>NHS Number:</h6>
                    <code>{token.nhsNumber}</code>
                  </div>
                  <div>
                    <h6>NHSD-ID-Token</h6>
                    <code>{token.value}</code>
                  </div>
                </div>
              )}
              <div className='d-flex flex-column mx-auto'>
                {!token && <p>To test your AOS connection, you first need to sign in using NHS Login:</p>}
                <NhsLoginButton />
                {token && (
                  <Button className='mt-4' onClick={sendRequest}>
                    Send Request
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Input
            label='NHS Number'
            containerClassName='w-100'
            value={nhsNumber}
            onChange={onNhsNumberChange}
            maxLength={10}
            componentRight={
              <Button disabled={nhsNumber.length !== 10} onClick={sendRequest}>
                Send Request
              </Button>
            }
          />
        )}
        <hr />
        <div className='my-2 py-2 json-view-container'>
          {response.loading ? (
            <Loading />
          ) : response.error ? (
            <>
              <h5>Error Sending Request to aggregator:</h5>
              <ResponseData data={response.error.response?.data} />
            </>
          ) : response.data ? (
            <>
              <div className='mb-4'>
                <h5>Full URL Queried:</h5>
                <p>{response.data.url ? decodeURIComponent(response.data.url) : 'N/A'}</p>
              </div>
              {(response.data.error || response.data.message) && (
                <div className='mb-4'>
                  <h5>Error:</h5>
                  <p>{response.data.error}</p>
                  <p>{response.data.message}</p>
                </div>
              )}
              {fhirValidationErrors.length > 0 && (
                <div className='mb-4'>
                  <h5>Fhir Validation Errors</h5>
                  <table className='w-auto'>
                    <thead>
                      <tr>
                        <th>Location</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fhirValidationErrors.map((e, i) => (
                        <tr key={i}>
                          <td className='pe-5'>
                            <code>{e.location}</code>
                          </td>
                          <td>
                            <small>{e.message}</small>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {response.data?.mappingErrors?.length > 0 && (
                <div className='mb-4'>
                  <h5>Mapping Validation Errors</h5>
                  <table>
                    <thead>
                      <tr>
                        <th>Resource ID</th>
                        <th>Source</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {response.data.mappingErrors.map((e, i) => (
                        <tr key={i}>
                          <td>
                            <code>{e.resourceId}</code>
                          </td>
                          <td>
                            <code>{e.sourcePath}</code>
                          </td>
                          <td>
                            <small>{e.message}</small>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <>
                <h5>Response Data:</h5>
                <ResponseData data={response.data.data} />
              </>
            </>
          ) : (
            <div className='d-flex flex-row justify-content-center'>
              <h5 className='text-muted'>Send a request to view response data</h5>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
