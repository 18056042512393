import { useField } from 'formik';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export const Input = (
  /** @type {InputProps & {componentRight: any}} */ {
    containerClassName,
    touched = false,
    componentRight,
    error,
    label,
    ...rest
  }
) => {
  rest.placeholder = rest.placeholder || label;

  const children =
    rest.type === 'select' ? <Form.Select className='valid' {...rest} /> : <Form.Control className='valid' {...rest} />;

  return (
    <div className={`w-100 my-1 d-flex flex-column ${containerClassName || ''}`}>
      <InputGroup className={`w-100 ${label ? 'form-floating' : ''}`}>
        {children}
        {label && <label>{label}</label>}
        {componentRight}
      </InputGroup>
      {touched && error && (
        <Form.Control.Feedback type='invalid' className='ms-1 d-inline'>
          {error}
        </Form.Control.Feedback>
      )}
    </div>
  );
};

export const FormInput = (/** @type {InputProps} */ props) => {
  const [field, { touched, error }] = useField(props.name);

  let onChange = field.onChange;
  if (props.onChange) {
    onChange = (v) => {
      field.onChange(v);
      props.onChange(v);
    };
  }

  return <Input {...props} {...field} touched={touched} error={error} onChange={onChange} />;
};

/**
 * @typedef {import('react-bootstrap').FormControlProps & {containerClassName?: string, name?: string; label?: string}} InputProps
 */
