import React, { Fragment } from 'react';
import { Button } from './Button';
import { Removable } from './Removable';

export const ListInput = ({ items, component, onAdd, onRemove, addButtonLabel, ...rest }) => {
  return (
    <>
      {items.map((item, index) => (
        <div key={index} className='w-100 d-flex flex-row'>
          <Removable onRemoveClick={() => onRemove(index)}>
            {React.createElement(component, { ...rest, key: index, index, item })}
          </Removable>
        </div>
      ))}
      <Button size='sm' className='mt-2' onClick={onAdd}>
        {addButtonLabel || (
          <Fragment>
            <b>+</b> Add
          </Fragment>
        )}
      </Button>
    </>
  );
};
