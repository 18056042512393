import { Card } from 'react-bootstrap';
import { InlineIconButton, RemoveButton} from './InlineIconButton';

export const CardItemHeader = ({ title, onEditClick, onRemoveClick }) => {
  return (
    <Card.Header className='d-flex flex-row align-items-center'>
      <Card.Title className='m-0 w-100'>{title}</Card.Title>
      <InlineIconButton active src='/edit.svg' onClick={onEditClick} />
      <RemoveButton onClick={onRemoveClick} />
    </Card.Header>
  );
};
