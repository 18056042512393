const mock = {
  tests: [
    {
      id: '000000000000000000000001',
      name: 'Test1',
      status: 'active',
      cases: [
        {
          name: 'C1',
          rate: 1,
          duration: 1,
        },
        {
          name: 'C2',
          rate: 2,
          duration: 20,
        },
      ],
    },
    {
      id: '000000000000000000000002',
      name: 'Test2',
      status: 'archived',
      cases: [
        {
          name: 'C3',
          rate: 5,
          duration: 10,
        },
      ],
    },
  ],

  users: [
    {
      id: '1',
      name: 'User1',
    },
    {
      id: '2',
      name: 'User2',
    },
    {
      id: 'auth0|62d08f9653458976d6643e83',
      name: 'oscar-v0',
    },
  ],

  groups: [
    {
      id: '1',
      users: ['1'],
      tests: ['000000000000000000000002'],
      name: 'Group1',
    },
    {
      id: '2',
      users: ['1', '2'],
      tests: ['000000000000000000000001'],
      name: 'Group2',
    },
  ],
};

export default mock;
