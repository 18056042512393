import { useField } from 'formik';
import ReactSwitch from 'react-switch';

export const Switch = (/** @type {import('react-switch').ReactSwitchProps} */ { containerClassName, label, ...rest }) => {
  return (
    <div className={`d-flex flex-row align-items-center ${containerClassName || ''}`}>
      <div className='me-2'>{label}</div>
      <ReactSwitch
        width={40}
        height={20}
        handleDiameter={22}
        boxShadow='0px 2px 5px rgba(0, 0, 0, 0.5)'
        activeBoxShadow='0px 0px 1px 6px rgba(0, 0, 0, 0.3)'
        checkedIcon={false}
        uncheckedIcon={false}
        offColor='#bc2525'
        onColor='#058903'
        {...rest}
      />
    </div>
  );
};

export const FormSwitch = (
  /** @type {import('react-switch').ReactSwitchProps & {name: string}} */ { name, ...rest }
) => {
  const [field, , helper] = useField(name);
  return <Switch {...rest} checked={field.value} onChange={(v) => helper.setValue(v)} />;
};
