import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useActiveResourceType } from '../context';

/** @type {import('axios-hooks').ResponseValues} */
const stubResponseValues = {
  response: undefined,
  data: undefined,
  error: null,
  loading: false,
};

export const createUseResponseHook = (
  /** @type {import('@auth0/auth0-react').Auth0ContextInterface} */ { getAccessTokenSilently } = {},
  /** @type {import('axios').AxiosRequestConfig & {addResourceTypeParam?: boolean}} */ {
    addResourceTypeParam,
    ...config
  } = {}
) => {
  const axiosInstance = axios.create(config);

  if (getAccessTokenSilently) {
    axiosInstance.interceptors.request.use(
      async (config) => {
        return {
          ...config,
          headers: {
            ...config?.headers,
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        };
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  axiosInstance.interceptors.response.use(
    (res) => {
      return Promise.resolve(res);
    },
    (err) => {
      const { status, data = {} } = err?.response || {};
      if (status >= 400 && status < 500 && data.message) {
        toast(data.message, { type: 'warning' });
      }

      return Promise.reject(err);
    }
  );

  const useAxios = makeUseAxios({
    axios: axiosInstance,
    defaultOptions: {
      manual: true,
    },
  });

  return (
    /** @type {import('axios').AxiosRequestConfig | ((params: any) => import('axios').AxiosRequestConfig)} */ config
  ) => {
    const refConfig = useRef();
    const resourceType = useActiveResourceType();
    const [response, setResponse] = useState(stubResponseValues);
    const [rawResponse, doExecute] = useAxios();

    useEffect(() => {
      setResponse(rawResponse);
    }, [rawResponse]);

    const withResourceTypeParam = (c = {}) => {
      return {
        ...c,
        ...(addResourceTypeParam && {
          params: {
            ...c.params,
            resourceType,
          },
        }),
      };
    };

    return {
      ...response,
      clear: () => setResponse(stubResponseValues),
      execute:
        typeof config !== 'function'
          ? () => doExecute(withResourceTypeParam(config))
          : (params) => {
              if (params || !refConfig.current) {
                refConfig.current = config(params);
              }

              return doExecute(withResourceTypeParam(refConfig.current));
            },
    };
  };
};
